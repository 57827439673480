/* eslint-disable no-undef */
import _ from "lodash"
// import { DOMAIN, FB_APP_ID } from "../../../env"

const serverAPIPath = "http://localhost:5000"
const FB_APP_ID = "135030567183835"

function getAllEdges(apiResponse, allEdges = []) {
  return new Promise((resolve, reject) => {
    let edges = allEdges
    edges = [...allEdges, ...apiResponse.data]
    if (_.get(apiResponse, "paging.next")) {
      const url = apiResponse.paging.next
      return fetch(url)
        .then(res => res.json())
        .then(response => getAllEdges(response, edges))
        .then((result) => {
          resolve(result)
        })
    }
    resolve(edges)
  })
}

export function getManagedPages(accessToken) {
  console.log("getManagedPages accessToken", accessToken)
  return new Promise((resolve, reject) => {
    FB.api("/me/accounts", {
      access_token: accessToken,
    }, (response) => {
      if (response && !response.error) {
        console.log(response)
        getAllEdges(response)
          .then((pages) => {
            resolve(pages)
          })
      } else {
        reject({
          error: response.error
        })
      }
    })
  })
}

export function checkPermission(accessToken) {
  return new Promise((resolve, reject) => {
    fetch(`${serverAPIPath}/facebook/checkPermission`, {
      method: "POST",
      body: JSON.stringify({ accessToken }),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then((response) => {
        if (response) {
          const { data } = response
          const { is_valid, scopes, error } = data
          resolve({
            valid: is_valid,
            scopes,
            error
          })
        } else {
          resolve({
            valid: false,
            scopes: [],
          })
        }
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })
}

export function checkSubscribedApp(page) {
  return new Promise((resolve, reject) => {
    FB.api(
      `/${page.id}/subscribed_apps`,
      {
        access_token: page.access_token,
      },
      (response) => {
        if (response && response.error) {
          reject({
            error: response.error
          })
        }
        const { data } = response
        const targetApp = _.find(data, { id: FB_APP_ID })
        if (targetApp) {
          resolve(true)
        } else {
          resolve(false)
        }
      })
  })
}

export function getGetStartedNPersistentMenu(accessToken) {
  return new Promise((resolve, reject) => {
    FB.api(
      "/me/messenger_profile",
      {
        access_token: accessToken,
        fields: "persistent_menu,get_started"
      },
      (response) => {
        if (response && !response.error) {
          const { data } = response
          if (data.length) {
            resolve(data[0])
          } else {
            resolve({})
          }
        } else {
          reject({
            error: response.error
          })
        }
      })
  })
}

export function setMessengerProfile({
  accessToken,
  persistentMenu,
  getStartedPayload
}) {
  return new Promise((resolve, reject) => {
    const body = {}
    if (!_.isEmpty(persistentMenu)) {
      body.persistent_menu = persistentMenu
    }
    if (getStartedPayload) {
      body.get_started = {
        payload: getStartedPayload,
      }
    }
    FB.api(
      "/me/messenger_profile",
      "post",
      {
        access_token: accessToken,
        ...body
      },
      (response) => {
        if (response && !response.error) {
          resolve(response)
        } else {
          reject({
            error: response.error
          })
        }
      })
  })
}

export function removeMessengerProfile({
  accessToken,
  persistentMenu,
  getStarted
}) {
  return new Promise((resolve, reject) => {
    const fields = []
    if (persistentMenu) {
      fields.push("persistent_menu")
    }
    if (getStarted) {
      fields.push("get_started")
    }
    FB.api(
      "/me/messenger_profile",
      "delete",
      {
        access_token: accessToken,
        fields
      },
      (response) => {
        if (response && !response.error) {
          resolve(response)
        } else {
          reject({
            error: response.error
          })
        }
      })
  })
}
