import PropTypes from "prop-types"
import React from "react"
import "./SegmentedSelect.scss"

const propTypes = {
  disabled: PropTypes.bool,
  items: PropTypes.array,
  value: PropTypes.any,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
}

const defaultProps = {
  disabled: false,
  items: [],
  value: "",
  defaultValue: "",
  className: "",
  onChange: () => {},
}

class SegemnetedSelect extends React.Component {
  render() {
    const {
      disabled,
      items,
      value,
      defaultValue,
      className,
      onChange,
      readOnly
    } = this.props

    let finalValue = defaultValue
    if (value) {
      finalValue = value
    }

    return (
      <div
        className={`radiate-theme segmented-select-container ${className} ${disabled ? "disabled" : ""} ${readOnly ? "readOnly" : ""}`}
      >
        {items.map(item => (
          <div
            key={item.value}
            className={`option ${item.value === finalValue ? "selected" : ""} ${item.disabled ? "disabled" : ""}`}
            onClick={() => {
              if (!disabled && !item.disabled && !readOnly) {
                onChange(item.value)
              }
            }}
          >
            {item.text}
          </div>
        ))}
      </div>
    )
  }
}

SegemnetedSelect.propTypes = propTypes
SegemnetedSelect.defaultProps = defaultProps

export default SegemnetedSelect
