import React, { Component } from "react"
import _ from "lodash"
import Select from "react-select"

import PersistentMenu from "./PersistentMenu"
import { pureLocaleList } from "./util"
import { addRandomIdToPMOptions, removeRandomIdFromPMOptions } from "./persistentMenuHelpers"

import DropdownMenu from "./Radiate/DropdownMenu/DropdownMenu"
import SegmentedSelect from "./Radiate/SegmentedSelect/SegmentedSelect"

const locales = [{ label: "Default", value: "default" }, ...pureLocaleList.map(locale => ({
  value: locale.value,
  label: locale.text
}))]

const PersistentMenuWrapper = class PersistentMenuWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = {
      advancedString: this.parsePMToAdvancedString(this.props.pm || {}),
      mode: "basic",
      // open: true,
    }
  }

  // collapse() {
  //   this.setState({
  //     open: false
  //   })
  // }

  // open() {
  //   this.setState({
  //     open: true
  //   })
  // }

  parsePMToAdvancedString(pm) {
    let newPM = _.omit(pm, ["locale"])
    newPM = removeRandomIdFromPMOptions(newPM)
    return JSON.stringify(newPM, null, 2)
  }

  render() {
    const { pm, readOnly } = this.props
    const { locale } = pm
    return (
      <div className="facebook-persistent-menu-settings-container">
        <div className="facebook-locale-display-row">
          {locale === "default" ?
            <div className="facebook-locale-title">Default</div>
            :
            <Select
              className="facebook-locale-selector"
              options={locales}
              isDisabled={readOnly}
              value={locales.find(o => o.value === locale)}
              isOptionDisabled={option =>
                 this.props.selectedLocales.includes(option.value)
              }
              onChange={(value) => {
                this.props.onChange({
                  key: "locale",
                  value: value.value
                })
              }}
              styles={{
                menu: styles => ({
                  ...styles,
                  width: "calc(100% - 24px)",
                }),
                control: styles => ({
                  ...styles,
                  minHeight: 0,
                }),
                valueContainer: styles => ({
                  ...styles,
                  paddingTop: 0,
                  paddingBottom: 0,
                }),
                dropdownIndicator: styles => ({
                  ...styles,
                  padding: 6
                })
              }}
            />
          }
          <div className="button-wrapper">
            {/* <FontAwesomeIcon
              className="icon"
              icon={this.state.open ? faChevronUp : faChevronDown}
              onClick={() => {
                this.setState({
                  open: !this.state.open
                })
              }}
            /> */}
            <DropdownMenu
              className="action-button"
              key="delete-button"
              inverted
              placement="bottom-end"
              buttons={[
                {
                  label: "Remove",
                  danger: true,
                  disabled: locale === "default" || readOnly,
                  notAuth: readOnly,
                  onClick: () => {
                    this.props.onRemoveLocale()
                  }
                }
              ]}
            />
          </div>
        </div>
        <div className="facebook-locale-wrapper">
          <div className="facebook-locale-wrapper-top-bar">
            <SegmentedSelect
              items={[{
                text: "Basic",
                value: "basic"
              }, {
                text: "Advanced",
                value: "advanced"
              }]}
              defaultValue="basic"
              value={this.state.mode}
              onChange={(value) => {
                if (value === "advanced") {
                  this.setState({
                    advancedString: this.parsePMToAdvancedString(this.props.pm || {}),
                    mode: "advanced"
                  })
                } else {
                  this.setState({
                    mode: "basic"
                  })
                }
              }}
            />
          </div>
          <PersistentMenu
            ref={(c) => { this.pm = c }}
            mode={this.state.mode}
            readOnly={readOnly}
            pm={pm}
            advancedString={this.state.advancedString}
            onChange={(change) => {
              this.props.onChange(change)
            }}
            onChangePM={(newPM) => {
              const newPMClone = _.cloneDeep(newPM)
              newPMClone.locale = locale
              this.props.onChangePM(addRandomIdToPMOptions(newPMClone))
            }}
          />
        </div>
          
      </div>
    )
  }
}

export default PersistentMenuWrapper
