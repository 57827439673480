import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
import $ from "jquery"

import Toggle from "../Toggle/Toggle"

import "./ToggleField.scss"

const propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.bool,
  defaultValue: PropTypes.bool,
  error: PropTypes.object,
}

const defaultProps = {
  label: "",
  onChange: () => {},
  disabled: false,
  readOnly: false,
  defaultValue: false,
  value: undefined,
  onColor: "#39b34a",
  offColor: "#e3e5e8",
  className: "",
  error: {},
}

class ToggleField extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      focused: false
    }

    this.onGlobalClick = this.onGlobalClick.bind(this)
  }

  componentDidMount() {
    if (!this.props.readOnly) {
      document.addEventListener("click", this.onGlobalClick)
    }
  }

  componentWillUnmount() {
    if (!this.props.readOnly) {
      document.removeEventListener("click", this.onGlobalClick)
    }
  }

  onGlobalClick(e) {
    if ($(e.target).closest($(this.field)).length <= 0) {
      this.setState({
        focused: false
      })
    }
  }

  render() {
    const {
      label, className, disabled, error, readOnly
    } = this.props
    const propsToPassDown = _.omit(this.props, ["label", "className", "readOnly"])
    const hasError = !_.isEmpty(error)
    return (
      <div
        ref={(c) => { this.field = c }}
        className={`radiate-theme field-container toggle-field-container ${this.state.focused ? "focused" : ""} ${disabled ? "disabled" : ""} ${className} ${hasError ? "danger" : ""}`}
      >
        {label ?
          <div className="label">{label}</div>
          :
          null
        }
        <div className="content">
          <Toggle
            onFocus={() => {
              this.setState({
                focused: true
              })
            }}
            {...propsToPassDown}
            disabled={disabled || readOnly}
          />
        </div>
        {hasError ?
          <div className="error-container">
            <div className="text">{error.text}</div>
          </div>
          :
          null
        }
      </div>
    )
  }
}

ToggleField.propTypes = propTypes
ToggleField.defaultProps = defaultProps

export default ToggleField
