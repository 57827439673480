import React from "react"
import PropTypes from "prop-types"
import _ from "lodash"
// import ReactTooltip from "react-tooltip"

import { hexToRgb } from "../../util"
// import uuid from "uuidv4"
import "./Toggle.scss"

// const StyledReactToolTip = styled(ReactTooltip)`
//   box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
//   padding: 6px 10px !important;
//   white-space: nowrap;
//   border-radius: 4px !important;
//   font-size: 0.8rem !important;
  
//   &:before, &:after {
//     border-width: 0px !important;
//   }
// `
// const TooltipTarget = styled.div`
//   position: absolute;
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// `

const propTypes = {
  disabled: PropTypes.bool,
  defaultValue: PropTypes.bool,
  value: PropTypes.bool,
  onColor: PropTypes.string,
  offColor: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
}

const defaultProps = {
  disabled: false,
  defaultValue: false,
  value: undefined,
  onColor: "#39b34a",
  offColor: "#e3e5e8",
  className: "",
  onChange: () => {},
  onFocus: () => {}
}

const Toggle = class Toggle extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      // tooltipId: uuid()
    }
  }

  // componentDidUpdate(prevProps, prevState) {
  //   if (this.props.notAuth !== prevProps.notAuth) {
  //     ReactTooltip.rebuild()
  //   }
  // }
  render() {
    const {
      disabled,
      value,
      onColor,
      offColor,
      className,
      onFocus,
      onChange,
      notAuth,
      notAuthPlacement,
      ...props
    } = this.props

    let defaultClassName = "radiate-theme toggle-container"
    const on = value

    const rootProperty = {
      disabled,
      on,
      className,
    }

    defaultClassName = _.reduce(rootProperty, (classNames, val, key) => {
      if (val) {
        if (key === "className") {
          return `${classNames} ${val}`
        }
        return `${classNames} ${key}`
      }
      return classNames
    }, defaultClassName)

    const style = {
      backgroundColor: offColor
    }

    if (disabled) {
      if (on) {
        const rgb = hexToRgb(onColor)
        style.backgroundColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`
      } else {
        const rgb = hexToRgb(offColor)
        style.backgroundColor = `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.3)`
      }
    } else if (on) {
      style.backgroundColor = onColor
    }

    return (
      <div
        style={style}
        className={defaultClassName}
        // data-tip={"You are not authorised to perform this action."}
        // data-for={this.state.tooltipId}
        onClick={() => {
          if (!disabled) {
            onChange(!value)
            onFocus()
          }
        }}
        {...props}
      >
        <span className="handle"></span>
        {/* <StyledReactToolTip
          effect="solid"
          id={this.state.tooltipId}
          backgroundColor="#6544f4"
          textColor="#ffffff"
          disable={!notAuth}
          offset={{ top: -3, right: 7 }}
          place={notAuthPlacement ? notAuthPlacement : "top"}
        /> */}
      </div>
    )
  }
}

Toggle.defaultProps = defaultProps
Toggle.propTypes = propTypes

export default Toggle
