import React from "react"
import styled from "styled-components"

const Container = styled.div`
  border-radius: 4px;
  border: 1px solid ${(props) => {
    if (props.$color) {
      return props.$color
    }
    return props.theme.themeText
  }};
  overflow: hidden;
  font-size: ${(props) => {
    if (props.size === "S") {
      return "0.75rem"
    }
    return props.theme.textSm
  }};
  margin-right: 8px;
  display: inline-flex;
  align-items: center;
`

const Label = styled.div`
  background-color: ${(props) => {
    if (props.$color) {
      return props.$color
    }
    return props.theme.themeText
  }};
  padding: 3px 5px;
  color: #ffffff;
`

const Value = styled.div`
  color: ${(props) => {
    if (props.$color) {
      return props.$color
    }
    return props.theme.themeText
  }};
  padding: 3px 5px;
  background-color: white;
`

const ComplexTag = ({
  label,
  value,
  color,
  size,
  ...props
}) => (
  <Container
    size={size}
    $color={color}
    {...props}
  >
    <Label $color={color}>{label}</Label>
    <Value $color={color}>{value}</Value>
  </Container>
)

export default ComplexTag
