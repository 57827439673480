import _ from "lodash"
import React, { Component } from "react"
import PropTypes from "prop-types"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Droppable, Draggable, DragDropContext } from "react-beautiful-dnd"
import Select from "react-select"
import styled from "styled-components"

import { reorder, isJSONString, randomString } from "./util"
import { pmButtonSchema } from "./persistentMenuHelpers"

import DropdownMenu from "./Radiate/DropdownMenu/DropdownMenu"
import CodeEditor from "./Radiate/CodeEditor/CodeEditor"
import ToggleField from "./Radiate/ToggleField/ToggleField"

import "./PersistentMenu.scss"

const TextInput = styled.input`
  font-size: 1em;
  color: #626262;
  outline: none;
  margin-bottom: 3px;
  margin-top: 2px;
  /* margin-left: 4px;
  margin-right: 4px; */
  padding: 10px 6px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  overflow-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
`

const AddMoreButton = styled.div`
  border: 2px dashed #aaa;
  width: 90%;
  margin: 8px 0;
  font-size: 0.9rem;
  color: #777;
  padding: 8px;
  border-radius: 8px;
  text-align: center;
  box-sizing: border-box;
  cursor: ${props => props.readOnly ? "inherit" : "pointer"};

  .icon {
    margin-right: 8px;
  }

  &:hover {
    color: ${props => props.readOnly ? "#777" : props.theme.themeColorLight};
    border-color: ${props => props.readOnly ? "#aaa" : props.theme.themeColorLight};
  }
`

const propTypes = {
  pm: PropTypes.object
}

const defaultProps = {
  pm: {}
}

const PersistentMenu = class PersistentMenu extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedButtonIndex: null,
      jsonString: props.advancedString || ""
    }

    this.onDragEnd = this.onDragEnd.bind(this)
    this.addButton = this.addButton.bind(this)
    this.updateJSONString = _.debounce(this.updateJSONString.bind(this), 500)
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.jsonString !== nextState.jsonString) {
      this.updateJSONString(nextState.jsonString)
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.advancedString !== prevProps.advancedString) {
      this.setState({
        jsonString: this.props.advancedString
      })
    }
    if (this.state.jsonString !== prevState.jsonString) {
      this.updateJSONString(this.state.jsonString)
    }
  }

  updateJSONString(jsonString) {
    if (isJSONString(jsonString)) {
      this.props.onChangePM(JSON.parse(jsonString))
    }
  }

  onDragEnd(result) {
    if (!result.destination) {
      return
    }
    if (result.type === "button") {
      const newButtons = reorder(
        this.props.pm.call_to_actions,
        result.source.index,
        result.destination.index
      )

      this.setState({
        selectedButtonIndex: result.destination.index
      })

      this.props.onChange({
        key: "call_to_actions",
        value: newButtons
      })
    }
  }

  settings() {
    const { pm, readOnly } = this.props
    const { selectedButtonIndex } = this.state
    if (selectedButtonIndex !== null) {
      const button = _.get(pm, `call_to_actions[${selectedButtonIndex}]`)
      if (button) {
        const options = [{
          label: "Payload",
          value: "postback",
        }, {
          label: "URL",
          value: "web_url"
        }]
        return (
          <div className="settings-container">
            <div className="top-bar">
              <div className="title">Button Settings</div>
              
              <DropdownMenu
                className="action-button"
                key="delete-button"
                icon="ellipsis-h"
                inverted
                placement="bottom-end"
                buttons={[
                  {
                    
                    label: "Remove",
                    icon: "trash",
                    notAuth: readOnly,
                    danger: true,
                    disabled: readOnly,
                    onClick: () => {
                      const buttons = _.get(pm, "call_to_actions", [])
                      this.props.onChange({
                        key: "call_to_actions",
                        value: [
                          ...buttons.slice(0, selectedButtonIndex),
                          ...buttons.slice(selectedButtonIndex + 1)
                        ]
                      })
                      this.setState({
                        selectedButtonIndex: null
                      })
                    }
                  }
                ]}
              />
            </div>
            <div className="text-input-label" style={{ fontSize: "0.8rem", color: "#777777", marginBottom: "2px" }}>Title</div>
            <TextInput
              // placeholder="Title"
              value={button.title}
              readOnly={readOnly}
              borderStyle="M"
              onChange={(e) => {
                this.props.onChange({
                  key: `call_to_actions[${selectedButtonIndex}].title`,
                  value: e.target.value
                })
              }}
            />
            <div className="field-container button-type">
              <div className="label">Button Type</div>
              <Select
                className="button-type-selector"
                isDisabled={readOnly}
                defaultValue={{
                  label: "Payload",
                  value: "postback",
                }}
                name="button-type"
                options={options}
                value={options.find(o => o.value === button.type)}
                onChange={(value) => {
                  this.props.onChange({
                    key: `call_to_actions[${selectedButtonIndex}].type`,
                    value: value.value
                  })
                }}
                styles={{
                  menu: styles => ({
                    ...styles,
                    width: "calc(100% - 24px)"
                  })
                }}
              />
            </div>
            {this.typeSpecificSettings(button)}
          </div>
        )
      }
    }
    return (
      <div className="settings-container">
        <div className="title">Menu Settings</div>
        <ToggleField
          label="Disable User Text Input"
          defaultValue={false}
          readOnly={readOnly}
          notAuth={readOnly}
          className="composer_input_disabled-toggle"
          value={pm.composer_input_disabled}
          onChange={(value) => {
            this.props.onChange({
              key: "composer_input_disabled",
              value
            })
          }}
        />
      </div>
    )
  }

  typeSpecificSettings(button) {
    const { selectedButtonIndex } = this.state
    const { readOnly } = this.props
    const webviewHeightoptions = [{
      label: "Full",
      value: "full",
    }, {
      label: "Tall",
      value: "tall"
    }, {
      label: "Compact",
      value: "compact"
    }]
    switch (button.type) {
      case "postback":
        return (
          <div className="button-type-settings-container">
            <div className="text-input-label" style={{ fontSize: "0.8rem", color: "#777777", marginBottom: "2px" }}>Payload</div>
            <TextInput
              // placeholder="Payload"
              value={button.payload}
              readOnly={readOnly}
              borderStyle="M"
              onChange={(e) => {
                this.props.onChange({
                  key: `call_to_actions[${selectedButtonIndex}].payload`,
                  value: e.target.value
                })
              }}
            />
          </div>
        )
      case "web_url":
        return (
          <div className="button-type-settings-container">
            <div className="text-input-label" style={{ fontSize: "0.8rem", color: "#777777", marginBottom: "2px" }}>URL</div>
            <TextInput
              // placeholder="URL"
              readOnly={readOnly}
              borderStyle="M"
              value={button.url}
              onChange={(e) => {
                this.props.onChange({
                  key: `call_to_actions[${selectedButtonIndex}].url`,
                  value: e.target.value
                })
              }}
            />
            <div className="field-container webview-height-select">
              <div className="label">Webview Height Ratio</div>
              <Select
                className="webview-height-selector"
                isDisabled={readOnly}
                defaultValue={{
                  label: "Full",
                  value: "full",
                }}
                name="webview-height"
                options={webviewHeightoptions}
                value={webviewHeightoptions.find(o => o.value === button.webview_height_ratio)}
                onChange={(value) => {
                  this.props.onChange({
                    key: `call_to_actions[${selectedButtonIndex}].webview_height_ratio`,
                    value: value.value
                  })
                }}
                styles={{
                  menu: styles => ({
                    ...styles,
                    width: "calc(100% - 24px)"
                  })
                }}
              />
            </div>
            <ToggleField
              label="Webview Share Button"
              defaultValue
              readOnly={readOnly}
              className="webview-share-button-toggle"
              value={button.webview_share_button !== "hide"}
              onChange={(value) => {
                if (!value) {
                  this.props.onChange({
                    key: `call_to_actions[${selectedButtonIndex}].webview_share_button`,
                    value: "hide"
                  })
                } else {
                  this.props.onChange({
                    key: `call_to_actions[${selectedButtonIndex}].webview_share_button`,
                    value: "show"
                  })
                }
              }}
            />
            <ToggleField
              label="Messenger Extensions"
              className="messenger-extensions-toggle"
              value={button.messenger_extensions}
              readOnly={readOnly}
              onChange={(value) => {
                this.props.onChange({
                  key: `call_to_actions[${selectedButtonIndex}].messenger_extensions`,
                  value,
                })
              }}
            />
            {button.messenger_extensions ?
              <>
                <div className="text-input-label" style={{ fontSize: "0.8rem", color: "#777777", marginBottom: "2px" }}>Fallback URL</div>
                <TextInput
                  readOnly={readOnly}
                  borderStyle="M"
                  value={button.fallback_url}
                  onChange={(e) => {
                    this.props.onChange({
                      key: `call_to_actions[${selectedButtonIndex}].fallback_url`,
                      value: e.target.value
                    })
                  }}
                />
              </>
              :
              null
            }
          </div>
        )
      default:
        break
    }
  }

  addButton() {
    this.props.onChange({
      key: "call_to_actions",
      value: [..._.get(this.props.pm, "call_to_actions", []), {
        type: "postback",
        title: "Untitled",
        payload: "",
        id: randomString(10)
      }]
    })
  }

  basicView() {
    const { pm, readOnly } = this.props
    const buttonLimit = 20

    let headerEl = null
    if (pm.composer_input_disabled) {
      headerEl = (
        <div className="empty-input"></div>
      )
    } else {
      headerEl = (
        <div className="fake-input">Send a message...</div>
      )
    }
    return (
      <div className="persistent-menu-settings-wrapper basic">
        <div className="preview-container">
          <div className="pm-container">
            <div className="top-bar">
              <div className="bar"></div>
            </div>
            <div className="pm-content">
              {headerEl}
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable
                  droppableId="pm-button-droppable"
                  type="button"
                >
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      className="buttons-container"
                      {...provided.droppableProps}
                    >
                      {_.get(pm, "call_to_actions", []).map((button, i) => this.renderButton(button, i))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {_.get(pm, "call_to_actions.length", 0) < buttonLimit ?
                <AddMoreButton
                  readOnly={readOnly}
                  onClick={() => {
                    if (!readOnly) {
                      this.addButton()
                      console.log("this.state.selectedButtonIndex", this.state.selectedButtonIndex)
                      this.setState({
                        selectedButtonIndex: this.state.selectedButtonIndex ? this.state.selectedButtonIndex + 1 : 0
                      })
                    }
                  }}
                >
                  <FontAwesomeIcon
                    icon={faPlus}
                    className="icon"
                    fixedWidth
                  />
                  <span>Button</span>
                </AddMoreButton>
                :
                null
              }
            </div>
          </div>
        </div>
        {this.settings()}
      </div>
    )
  }

  advancedView() {
    return (
      <div className="persistent-menu-settings-wrapper advanced">
        <CodeEditor
          className="advanced-pm-editor"
          mode="jsx"
          height="100%"
          id="advanced-pm-editor"
          readOnly={this.props.readOnly}
          value={this.state.jsonString}
          onChange={(value, e) => {
            this.setState({
              jsonString: value
            })
          }}
        />
      </div>
    )
  }

  selectButton(index) {
    const { pm } = this.props
    const { selectedButtonIndex } = this.state
    if (selectedButtonIndex !== null) {
      const oldButton = _.get(pm, "call_to_actions", [])[selectedButtonIndex]
      try {
        pmButtonSchema.validateSync(oldButton)
        if (selectedButtonIndex === index) {
          this.setState({
            selectedButtonIndex: null
          })
        } else {
          this.setState({
            selectedButtonIndex: index
          })
        }
      } catch (error) {
        console.log(error)
        alert(error.message)
      }
    } else {
      this.setState({
        selectedButtonIndex: index
      })
    }
  }

  renderButton(button, index) {
    switch (button.type) {
      case "web_url":
      case "postback":
        return (
          <Draggable
            key={button.id}
            draggableId={`${button.title}${index}`}
            index={index}
            type="button"
            isDragDisabled={this.props.readOnly}
          >
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
              >
                <div
                  className={`pm-button general ${this.state.selectedButtonIndex === index ? "selected" : ""}`}
                  onClick={() => {
                    this.selectButton(index)
                  }}
                >
                  <div
                    className="drag-handle"
                    {...provided.dragHandleProps}
                  >
                    <FontAwesomeIcon
                      fixedWidth
                      icon={faBars}
                      className="icon"
                    />
                  </div>
                  <span>{button.title}</span>
                </div>
              </div>
            )}
          </Draggable>
        )
      default:
        return null
    }
  }

  render() {
    const { mode } = this.props

    if (mode === "basic") {
      return this.basicView()
    }
    return this.advancedView()
  }
}

PersistentMenu.propTypes = propTypes
PersistentMenu.defaultProps = defaultProps

export default PersistentMenu
