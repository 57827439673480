import _ from "lodash"
import { string, object, array, boolean, mixed } from "yup"
import { randomString } from "./util"

export function addRandomIdToPMOptions(pm) {
  if (_.get(pm, "call_to_actions.length")) {
    pm.call_to_actions = pm.call_to_actions.map((cta) => {
      if (!cta.id) {
        const clone = _.cloneDeep(cta)
        clone.id = randomString(10)
        return clone
      }
      return cta
    })
  }
  return pm
}

export function removeRandomIdFromPMOptions(pm) {
  if (_.get(pm, "call_to_actions.length")) {
    pm.call_to_actions = pm.call_to_actions.map((cta) => {
      const newCTA = _.omit(cta, ["id"])
      return newCTA
    })
  }
  return pm
}

export const pmButtonSchema = object().shape({
  type: string().oneOf(["postback", "web_url"]),
  title: string().required(),
  payload: string()
    .when("type", {
      is: "postback",
      then: string().required(),
      otherwise: string().strip()
    }),
  url: string()
    .when("type", {
      is: "web_url",
      then: string().url().required(),
      otherwise: string().strip()
    }),
  webview_height_ratio: string()
    .when("type", {
      is: "web_url",
      then: string().oneOf(["tall", "full", "compact"]),
      otherwise: string().strip()
    }),
  webview_share_button: mixed()
    .when("type", {
      is: "web_url",
      then: mixed().oneOf(["hide", false]),
      otherwise: mixed().strip()
    }),
  messenger_extensions: boolean()
    .when("type", {
      is: "web_url",
      then: boolean(),
      otherwise: boolean().strip()
    }),
  fallback_url: string()
    .when(["type", "messenger_extensions"], {
      is: (type, me) => type === "web_url" && me === true,
      then: string().url(),
      otherwise: string().strip()
    }),
})

export const persistentMenuSchema = object().shape({
  composer_input_disabled: boolean().default(false),
  locale: string().required(),
  call_to_actions: array().max(3).of(pmButtonSchema)
})

export const persistentMenusSchema = array().of(persistentMenuSchema)
