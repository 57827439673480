/* eslint-disable no-undef */
import React, { Component } from "react"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import Select from "react-select"
import _ from "lodash"
import ReactTooltip from "react-tooltip"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch, faCheckCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons'

import PersistentMenuWrapper from "../../components/PersistentMenuWrapper"
import { getManagedPages, checkSubscribedApp, getGetStartedNPersistentMenu, setMessengerProfile, removeMessengerProfile } from "../../components/fbHelpers"
import { removeRandomIdFromPMOptions, addRandomIdToPMOptions } from "../../components/persistentMenuHelpers"
import NewBoxButton from "../../components/Radiate/NewBoxButton/NewBoxButton"
import ComplexTag from "../../components/Radiate/ComplexTag/ComplexTag"

const SERVER_API_PATH = process.env.REACT_APP_SERVER_API_PATH
const DOMAIN = process.env.REACT_APP_DOMAIN

const PageSelect = styled(Select)`
  width: 400px;
  margin-right: 8px;
`
const StyledTooltip = styled(ReactTooltip)`
  white-space: pre-line;
  padding: 6px 8px !important;

  &:before, &:after {
    border-width: 0px !important;
  }
`

const BodyContainer = styled.div`
  padding: 0 64px 40px 64px;
  position: relative;

  >.separator {
    background: ${props => props.theme.gray1};
    margin-top: 32px;
    height: 1px;
    width: 100%;
  }
`

const H3Title = styled.h3`
  color: ${props => props.theme.gray7};
  padding-top: 16px;
`

const ActionBar = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 56px;
  border-bottom: 1px solid #e8e7e8;
  position: sticky;
  background: #ffffff;
  top: 0;
  z-index: 99;

.box-button {
    border: 1px solid rgb(204,204,204);
    border-radius: 4px;
    box-sizing: border-box;
    padding: 6px 10px;
    min-width: 34px;
    min-height: 34px;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`

const ErrorMessage = styled.div`
  color: ${props => props.theme.error};
  font-size: 0.75rem;
  padding-right: 8px;
`

const RadioButton = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;

  .radio-button-border {
    width: 24px;
    height: 24px;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    transition: border 0.1s ease-in-out;
    border: 2px solid ${(props) => {
      if (props.on) {
        return "#0063f0"
      }
      return "#bbbbbc"
    }};
    box-sizing: border-box;


    >.radio-button {
      box-sizing: border-box;
      transition: background-color 0.1s ease-in-out;
      background-color: ${(props) => {
       if (props.on) {
          return "#0063f0"
        }
        return "#ffffff"
      }};
      width: 14px;
      height: 14px;
      border-radius: 7px;
    }
  }

  >span {
    padding-left: 8px;
    color: #626262;
    font-size: 0.85rem;
  }
`

const LoadingContainer = styled.div`
  min-height: 200px;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  >svg {
    width: 28px !important;
    height: 28px;
    color: ${props => props.theme.gray4};
  }
`

const LoadingIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  >svg {
    color: ${props => props.theme.gray4};
  }
`

const PageInfoContainer = styled.div`
  width: 100%;
  border: 1px solid #e8e7e8;
  border-radius: 8px;
  color: #707070;
  box-sizing: border-box;

  >.page-info-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.border {
      border-bottom: 1px solid #e8e7e8;
    }

    >.page-wrapper {
      padding: 16px;
      width: 100%;
    }
  }
`
const TableRow = styled.tr`
  font-size: 0.75rem;

  .value {
    word-break: break-all;
    padding: 4px 8px;
  }
`
const Label = styled.td`
  white-space: nowrap;
  padding: 4px 8px 4px 0;
  font-weight: 600;
  width: 108px;
  box-sizing: border-box;

  .sub-text {
    font-weight: 400;
    color: #777777;
  }
`

const StatusWrapper = styled.div`
  padding: 0 16px;

  .status-icon {
    &.stable {
      color: ${props => props.theme.success};
    }

    &.danger {
      color: ${props => props.theme.error};
    }
  }
`

const CommentReplyContainer = styled.div`
  padding: 16px;
  font-size: 0.75rem;
`

const TextInput = styled.input`
  font-size: 1em;
  color: #626262;
  outline: none;
  margin-bottom: 3px;
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 6px;
  border: 1px solid #cccccc;
  border-radius: 4px;
  overflow: hidden;
  overflow-wrap: break-word;
  width: 100%;
  box-sizing: border-box;
`

const StepTitle = styled.div`
  display: flex;
  align-items: center;

  >.step-number {
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 14px;
    background-color: #0063f0;
    font-size: 0.8rem;
    color: #ffffff;
  }

   >.step-text {
    padding-left: 8px;
    color: ${props=> props.theme.gray7};
    font-size: 0.9rem;
    font-weight: bold;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 8px;

  >:first-child {
    margin-right: 8px;
  }
`

export function randomString(length) {
  const chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ"
  let result = ""
  for (let i = length; i > 0; --i) {
    result += chars[Math.floor(Math.random() * chars.length)]
  }
  return result
}

class Home extends Component {
  constructor(props) {
    super(props)
    this.state = {
      defaultApp: true,
      info: {},
      originalInfo: {},
      loading: false,
      userAccessToken: null,
      pages: [],
      selectedPage: "",
      checkingAT: false,
      ATValid: false,
      ATMessage: null,
      ATComment: null,
      ATSubscribed: null,
      getStartedPayload: null,
      originalGetStartedPayload: null,
      persistentMenu: [],
      originalPersistentMenu: [],
      checkingPageSubscribed: false,
      pageSubscribed: false,
      fbError: null,
      channelObj: null,
      savingError: null,
    }
    this.receiveMessage = this.receiveMessage.bind(this)
  }

  componentDidMount() {
    if (navigator.userAgent !== "ReactSnap") {
      window.addEventListener("message", this.receiveMessage, false)
      window.parent.postMessage("iframeFinishLoading", DOMAIN)
      this.getDataFromDb()
    }    
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.userAccessToken !== this.state.userAccessToken) {
      try {
        getManagedPages(this.state.userAccessToken)
          .then((pages) => {
            if (pages.error) {
              this.setState({
                fbError: `(${pages.error.code}) ${pages.error.message}`
              })
            } else {
              this.setState({
                pages,
              })
            }
          })
      } catch (error) {
      }
    }

    if (!_.isEqual(prevState.channelObj, this.state.channelObj) && this.state.channelObj) {
      this.getDataFromDb()
    }

    if (!_.isEqual(prevState.info, this.state.info)) {
      this.getInitData()
      this.checkToken()
    }

    if ((prevState.ATValid !== this.state.ATValid && this.state.ATValid) || (this.state.info?.accessToken !== prevState.info?.accessToken)) {
      const accessToken = _.get(this.state.info, "accessToken", "")
      try {
        getGetStartedNPersistentMenu(accessToken)
          .then((data) => {
            if (data.error) {
              this.setState({
                fbError: `(${data.error.code}) ${data.error.message}`
              })
            } else {
              this.setState({
                getStartedPayload: _.get(data, "get_started.payload", ""),
                persistentMenu: _.get(_.cloneDeep(data), "persistent_menu", []).map(o => addRandomIdToPMOptions(o)),
                originalGetStartedPayload: _.get(data, "get_started.payload", ""),
                originalPersistentMenu: data.persistent_menu
              })
            }
          })
      } catch (error) {
        this.setState({
          fbError: error
        })
    }
    }

    if (this.state.selectedPage !== prevState.selectedPage && this.state.selectedPage) {
      this.setState({
        checkingPageSubscribed: true
      })
      const page = this.state.pages.find(o => o.id === this.state.selectedPage)
      checkSubscribedApp(page)
        .then((subscribed) => {
          if (subscribed.error) {
            this.setState({
              fbError: `(${subscribed.error.code}) ${subscribed.error.message}`
            })
          } else {
            this.setState({
              checkingPageSubscribed: false,
              pageSubscribed: subscribed
            })
          }
        })
        .catch(() => {
          this.setState({
            checkingPageSubscribed: false
          })
        })
    }
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.receiveMessage, false)
  }

  receiveMessage(event) {
    if (event.origin !== DOMAIN) {
      return
    }
    if (!_.isEmpty(event.data?.payload)) {
      this.setState({
        channelObj: event.data
      })
    }

    if (event.data?.unsubscribe) {
      this.getDataFromDb()
    }
  }

  async getDataFromDb() {
    if (!_.isEmpty(this.state.channelObj)) {
      // console.log("context", this.state.channelObj)
      this.setState({
        loading: true
      })
      const result = await fetch(`${SERVER_API_PATH}/handler`, {
        method: "POST",
        body: JSON.stringify(this.state.channelObj),
        headers: {
          "Content-Type": "application/json"
        }
      }).then(res => res.json())
      if (result.ok) {
        this.setState({
          loading: false,
          info: result?.data?.info,
          originalInfo: result?.data?.info
        })
      } else {
        this.setState({
          loading: false
        })
      }
    }
  }

  async checkPermission(accessToken) {
    const permResult = await fetch(`${SERVER_API_PATH}/check-permission`, {
      method: "POST",
      body: JSON.stringify({ accessToken: accessToken }),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => res.json())
    return permResult
  }

  async updateFacebookInfo() {
    const data = {
      payload: this.state.channelObj?.payload,
      signedContext: this.state.channelObj?.signedContext,
      info: this.state.info
    }
    const result = await fetch(`${SERVER_API_PATH}/update-facebook-info`, {
      method: "POST",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json"
      }
    }).then(res => res.json())
    if (result.ok) {
      this.setState({
        originalInfo: this.state.info,
        info: this.state.info,
        savingError: null,
      })
    } else {
      this.setState({
        savingError: "Something went wrong."
      })
    }
  }

  async checkToken() {
    if (_.get(this.state.info, "customApp")) {
      this.setState({
        ATValid: true
      })
      return
    }
    if (_.get(this.state.info, "accessToken.length")) {
      this.setState({
        checkingAT: true
      })
      const mod = {
        ATValid: false,
        ATMessage: false,
        ATComment: false,
        ATSubscribed: false,
      }
      const result = await this.checkPermission(this.state.info?.accessToken)
      if (result.ok) {
        mod.checkingAT = false
        if (result.valid) {
          mod.ATValid = true
        }
        if (result.error) {
          this.setState({
            fbError: `(${result.error.code}) ${result.error.message}`
          })
        }
        if (_.difference(["pages_manage_ads", "pages_manage_metadata", "pages_read_engagement", "pages_read_user_content", "pages_messaging"], result.scopes).length === 0) {
          mod.ATMessage = true
        }
        if (_.difference(["pages_manage_engagement"], result.scopes).length === 0) {
          mod.ATComment = true
        }
        const subscribed = checkSubscribedApp({
          id: this.state.info?.pageId,
          access_token: this.state.info?.accessToken
        })
        if (subscribed) {
          mod.ATSubscribed = true
        }
        this.setState({
          ...mod,
          checkingAT: false
        })
      }
    } else {
      this.setState({
        checkingAT: false,
        ATValid: null,
        ATMessage: null,
        ATComment: null,
        ATSubscribed: null,
      })
    }
  }

  getInitData() {
    FB.getLoginStatus(async (response) => {
      if (response.status === "connected") {
        const result = await fetch(`${SERVER_API_PATH}/token-exchange`, {
          method: "POST",
          body: JSON.stringify({ accessToken: response.authResponse.accessToken, userId: response.authResponse.userID }),
          headers: {
            "Content-Type": "application/json"
          }
        }).then(res => res.json())
        if (result.ok) {
          this.setState({
            userId: response.authResponse.userID,
            userAccessToken: result.accessToken,
          })
        } else {
          return Promise.reject(result.err)
        }
      }
    })
  }

  fbLogin(options) {
    FB.login(async (response) => {
      if (response.status === "connected") {
        const result = await fetch(`${SERVER_API_PATH}/token-exchange`, {
          method: "POST",
          body: JSON.stringify({ accessToken: response.authResponse.accessToken, userId: response.authResponse.userID }),
          headers: {
            "Content-Type": "application/json"
          }
        }).then(res => res.json())
        if (result.ok) {
          this.setState({
            userAccessToken: result.accessToken,
            checkingAT: true
          })
          const permResult = await this.checkPermission(result.accessToken)
          if (permResult.ok) {
            const mod = {
              ATValid: false,
              ATMessage: false,
              ATComment: false,
              ATSubscribed: false,
            }
            mod.checkingAT = false
            if (permResult.valid) {
              mod.ATValid = true
            }
            if (_.difference(["pages_manage_ads", "pages_manage_metadata", "pages_read_engagement", "pages_read_user_content", "pages_messaging"], result.scopes).length === 0) {
              mod.ATMessage = true
            }
            if (_.difference(["pages_manage_engagement", "pages_manage_posts"], result.scopes).length === 0) {
              mod.ATComment = true
            }
            this.setState({
              ...mod,
              checkingAT: false
            })
          }
        } else {
          throw new Error(result.err)
        }
      }
    }, { scope: options && options.commentReply ? "pages_manage_engagement" : "pages_manage_ads, pages_manage_metadata, pages_read_engagement, pages_read_user_content, pages_show_list, pages_messaging, pages_messaging_subscriptions, ads_management" })
  }

  subscribeAppToPage() {
    const { selectedPage, pages } = this.state
    const page = pages.find(o => o.id === selectedPage)
    FB.api(
      `/${page.id}/subscribed_apps`,
      "post",
      {
        access_token: page.access_token,
        subscribed_fields: "feed, messages, messaging_postbacks, messaging_optins, messaging_optouts, message_deliveries, message_reads, messaging_referrals, standby, messaging_handovers, messaging_account_linking, messaging_policy_enforcement"
      },
      (response) => {
        if (response && !response.error && response.success) {
          const modPage = _.cloneDeep(page)
          modPage.accessToken = modPage.access_token
          modPage.pageId = modPage.id
          delete modPage.access_token
          delete modPage.id
          this.setState({
            fbError: null,
            info: modPage
          })
        } else {
          console.log(response.error)
        }
      })
  }

  CustomOption({ innerProps,
    data,
    isFocused,
    isSelected
  }) {
    let bgColor
    let textColor
    if (isFocused) {
      bgColor = "#DEEBFF"
      textColor = "text"
      if (isSelected) {
        bgColor = "#2684FF"
        textColor = "white"
      }
    } else {
      bgColor = "white"
      textColor = "#3c3c3c"
    }
    return (
      <div 
        {...innerProps}
        style={{
          display: "flex",
          padding: "8px 12px",
          backgroundColor: bgColor,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span
          style={{
            color: textColor,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: "0.8rem",
          }}
          title={data.label}
        >
          {data.label}
        </span>
        <ComplexTag
          label="ID"
          value={data.value}
          color="#3B5998"
          size="S"
        />
      </div>
    )
  }

  CustomValue({ innerProps, data }) {
    return (
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0 8px" }} {...innerProps}>
        <span
          style={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: "0.8rem",
            marginRight: "8px",
            color: "#3c3c3c"
          }}
          title={data.label}
        >
          {data.label}
        </span>
        <ComplexTag
          label="ID"
          value={data.value}
          color="#3B5998"
          size="S"
        />
      </div>
    )
  }

  ValueContainer = ({
    children,
    ...props
  }) => (
    <div
      {...props}
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >{children}</div>
  );

  Placeholder = ({
    children,
    ...props
  }) => (
    <div
      {...props}
      style={{
        margin: "0 8px",
        color: "#707070"
      }}
    >{children}</div>
  );

  displayLocale(pm, index) {
    return (
      <PersistentMenuWrapper
        ref={(c) => {
          _.set(this, `menus[${index}]`, c)
        }}
        selectedLocales={this.state.persistentMenu.map(o => o.locale)}
        pm={pm}
        onChange={(change) => {
          const newPM = _.cloneDeep(this.state.persistentMenu)
          _.set(newPM, `${index}.${change.key}`, change.value)
          this.setState({
            persistentMenu: newPM
          })
        }}
        onChangePM={(newPM) => {
          this.setState({
            persistentMenu: [
              ...this.state.persistentMenu.slice(0, index),
              newPM,
              ...this.state.persistentMenu.slice(index + 1)
            ]
          })
        }}
        onRemoveLocale={() => {
          this.setState({
            persistentMenu: [
              ...this.state.persistentMenu.slice(0, index),
              ...this.state.persistentMenu.slice(index + 1)
            ]
          })
        }}
      />
    )
  }

  pmSettings() {
    const {
      getStartedPayload, persistentMenu, originalGetStartedPayload, originalPersistentMenu, info
    } = this.state
    let canSavePersistentMenu = true
    if (!getStartedPayload || _.isEmpty(persistentMenu) || _.isEqual(originalPersistentMenu, persistentMenu.map(o => removeRandomIdFromPMOptions(o)))) {
      canSavePersistentMenu = false
    }
    return (
      <div style={{ boxSizing: "border-box" }}>
        <div style={{ borderRadius: "4px", border: "1px solid #e8e7e8", padding: "24px" }} className="step-container get-started">
          <StepTitle>
            <span className="step-number">1</span>
            <span className="step-text">Get Started Button</span>
          </StepTitle>
          <div className="step-content">
            <div className="row" style={{ paddingTop: "16px" }}>
              <TextInput
                label="Payload"
                placeholder="GET_STARTED"
                value={getStartedPayload}
                onChange={(e) => {
                  this.setState({
                    getStartedPayload: e.target.value
                  })
                }}
              />
              <ButtonContainer>
                <NewBoxButton
                  primary
                  text="Save"
                  disabled={!getStartedPayload || getStartedPayload === originalGetStartedPayload}
                  onClick={() => {
                    setMessengerProfile({
                      accessToken: info?.accessToken,
                      getStartedPayload
                    })
                      .then((response) => {
                        if (response.result === "success") {
                          this.setState({
                            originalGetStartedPayload: getStartedPayload
                          })
                        } else if (response.error) {
                          this.setState({
                            fbError: `(${response.error.code}) ${response.error.message}`
                          })
                        }
                      })
                      .catch((err) => {
                        if (err.message) {
                          alert(err.message)
                        } else {
                          alert(err)
                        }
                      })
                  }}
                />
                <NewBoxButton
                  text="Remove"
                  danger
                  inverted
                  disabled={getStartedPayload === null}
                  onClick={() => {
                    removeMessengerProfile({
                      accessToken: info.accessToken,
                      getStarted: true
                    })
                      .then((response) => {
                        if (response.result === "success") {
                          this.setState({
                            getStartedPayload: null,
                            originalGetStartedPayload: null,
                          })
                        } else if (response.error) {
                          this.setState({
                            fbError: `(${response.error.code}) ${response.error.message}`
                          })
                        }
                      })
                      .catch((err) => {
                        if (err.message) {
                          alert(err.message)
                        } else {
                          alert(err)
                        }
                      })
                  }}
                />
              </ButtonContainer>
            </div>
          </div>
        </div>
        <div style={{ borderRadius: "4px", border: "1px solid #e8e7e8", padding: "24px", marginTop: "16px" }} className="step-container persistent-menu">
          <StepTitle>
            <span className="step-number">2</span>
            <span className="step-text">Persistent Menu</span>
            <NewBoxButton
              style={{ marginLeft: "8px" }}
              text="Add Language"
              className="add-language-button"
              primary
              onClick={() => {
                let newPM
                if (_.isEmpty(this.state.persistentMenu)) {
                  newPM = [...this.state.persistentMenu, {
                    locale: "default",
                    call_to_actions: [],
                    composer_input_disabled: false
                  }]
                } else {
                  newPM = [...this.state.persistentMenu, {
                    locale: "",
                    call_to_actions: [],
                    composer_input_disabled: false
                  }]
                }
                this.setState({
                  persistentMenu: newPM,
                })
              }}
            />
          </StepTitle>
          
          {_.isEmpty(this.state.persistentMenu) ?
            null
            :
            <ul className="locale-list">
              {this.state.persistentMenu.map((pm, i) => (
                <div key={pm.locale}>{this.displayLocale(pm, i)}</div>
              ))}
            </ul>
          }

          <div className="step-content">
            <ButtonContainer>
              <NewBoxButton
                text="Save"
                primary
                disabled={!canSavePersistentMenu}
                onClick={() => {
                  const filtered = persistentMenu.filter(o => _.get(o, "call_to_actions.length") && o.locale)
                  const menus = filtered.map(o => removeRandomIdFromPMOptions(o))
                  setMessengerProfile({
                    accessToken: info.accessToken,
                    persistentMenu: menus
                  })
                    .then((response) => {
                      if (response.result === "success") {
                        this.setState({
                          originalPersistentMenu: persistentMenu
                        })
                      }
                    })
                    .catch((err) => {
                      if (err.message) {
                        alert(err.message)
                      } else {
                        alert(err)
                      }
                    })
                }}
              />
              <NewBoxButton
                text="Remove"
                danger
                inverted
                disabled={(!getStartedPayload || _.isEmpty(persistentMenu))}
                onClick={() => {
                  removeMessengerProfile({
                    accessToken: info.accessToken,
                    persistentMenu: true
                  })
                    .then((response) => {
                      if (response.result === "success") {
                        this.setState({
                          persistentMenu: [],
                          originalPersistentMenu: []
                        })
                      }
                    })
                    .catch((err) => {
                      if (err.message) {
                        alert(err.message)
                      } else {
                        alert(err)
                      }
                    })
                }}
              />
            </ButtonContainer>
          </div>
        </div>
      </div>
    )
  }

  renderPageInfo() {
    const {
      pages,
      selectedPage,
      ATMessage,
      ATValid,
      ATComment,
      ATSubscribed,
      checkingAT,
      info
    } = this.state
    let tokenStatus = null
    let commentStatus = null
    if (checkingAT || ATMessage === null) {
      tokenStatus = (
        <LoadingIconContainer>
          <FontAwesomeIcon
            icon={faCircleNotch}
            spin
          />
        </LoadingIconContainer>
      )
    } else if (ATMessage && ATValid && ATSubscribed) {
      tokenStatus = (
        <FontAwesomeIcon
          className="status-icon stable"
          icon={faCheckCircle}
          fixedWidth
        />
      )
    } else {
      tokenStatus = (
        <>
          <FontAwesomeIcon
            className="status-icon danger"
            icon={faExclamationTriangle}
            fixedWidth
            data-tip
            data-for="fb-token-error"
          />
          <StyledTooltip
            effect="solid"
            id="fb-token-error"
            offset={{ top: -6 }}
            getContent={() => {
              if (!ATSubscribed) {
                return "Stella is not installed on your page. Please unsubscribe and connect again."
              }
              if (!ATValid) {
                return "The access token is invalid. Please unsubscribe and connect again."
              }
              if (!ATMessage) {
                return "The access token does not have sufficient permissions to perform chatbot functions. Please unsubscribe and connect again."
              }
            }}
          />
        </>
      )
    }

    if (checkingAT || ATMessage === null) {
      commentStatus = (
        <LoadingIconContainer>
          <FontAwesomeIcon
            icon={faCircleNotch}
            spin
          />
        </LoadingIconContainer>
      )
    } else if (ATComment && ATValid && ATSubscribed) {
      commentStatus = (
        <FontAwesomeIcon
          className="status-icon stable"
          icon={faCheckCircle}
          fixedWidth
        />
      )
    } else {
      commentStatus = (
        <NewBoxButton
          text="Allow"
          primary
          inverted
          className="request-comment-reply-button"
          onClick={() => {
            this.fbLogin({ commentReply: true })
          }}
        />
      )
    }

    if (this.state.defaultApp && info?.pageId && info?.accessToken) {
      return (
        <PageInfoContainer>
          <div className="page-info-wrapper border">
            <table className="page-wrapper">
              <tbody>
                <TableRow>
                  <Label>Page Name</Label>
                  <td className="value">{_.get(info, "name") || "N/A"}</td>
                </TableRow>
                <TableRow>
                  <Label>Page ID</Label>
                  <td className="value">{_.get(info, "pageId") || "N/A"}</td>
                </TableRow>
                <TableRow>
                  <Label>Access Token</Label>
                  <td className="value">{_.get(info, "accessToken") || "N/A"}</td>
                </TableRow>
              </tbody>
            </table>
            <StatusWrapper>
              {tokenStatus}
            </StatusWrapper>
          </div>
          <div className="page-info-wrapper">
            <CommentReplyContainer>
              <div className="sub-text">Comment reply automation</div>
              <div className="sub-text">Stella would need additional permissions to handle post comments</div>
            </CommentReplyContainer>
            <StatusWrapper>
              {commentStatus}
            </StatusWrapper>
          </div>
        </PageInfoContainer>
      )
    } else if (!this.state.defaultApp) {
      return (
        <PageInfoContainer>
          <div className="page-info-wrapper">
            <table className="page-wrapper">
              <tbody>
                <TableRow>
                  <Label>Page ID</Label>
                  <td>
                    <TextInput
                      placeholder="Facebook Page ID"
                      type="text"
                      value={_.get(info, "pageId")}
                      onChange={(e) => {
                        const currentInfo = _.clone(this.state.info)
                        currentInfo.pageId = e.target.value
                        this.setState({
                          info: currentInfo
                        })
                      }}
                    />
                  </td>
                </TableRow>
                <TableRow>
                  <Label>Access Token</Label>
                  <td>
                    <TextInput
                      placeholder="Facebook Page Access Token"
                      type="text"
                      value={_.get(info, "accessToken")}
                      onChange={(e) => {
                        const currentInfo = _.clone(this.state.info)
                        currentInfo.accessToken = e.target.value
                        this.setState({
                          info: currentInfo
                        })
                      }}
                    />
                  </td>
                </TableRow>
              </tbody>
            </table>
          </div>
        </PageInfoContainer>
      )
    } else if (pages && pages.length) {
      const pageOptions = pages.map(page => ({
        label: page.name,
        value: page.id,
        page
      }))
      return (
        <div style={{ paddingTop: "8px" }}>
          <div style={{ fontSize: "0.85rem", color: "#626262", paddingBottom: "8px" }}>Please choose a page:</div>
          <div style={{ display: "flex", alignItems: "center"}}>
            <PageSelect
              options={pageOptions}
              value={pageOptions.find(o => o.value === selectedPage)}
              components={{
                Option: this.CustomOption,
                SingleValue: this.CustomValue,
                ValueContainer: this.ValueContainer,
                Placeholder: this.Placeholder,
              }}
              onChange={(page) => {
                this.setState({
                  selectedPage: page.value
                })
              }}
            />
            <NewBoxButton
              primary
              text="Subscribe"
              className="connect-button"
              onClick={() => {
                this.subscribeAppToPage()
              }}
            />
          </div>
          
        </div>
      )
    }
    return (
      <div style={{ paddingTop: "8px" }}>
        <div style={{ fontSize: "0.85rem", color: "#626262", paddingBottom: "8px" }}>Connect with your facebook to choose which page your chatbot should reside.</div>
        <NewBoxButton
          text="Connect"
          primary
          className="connect-button"
          onClick={() => {
            this.fbLogin()
          }}
        />
      </div>
    )
  }

  render() {
    const { ATValid, originalInfo, info, savingError, loading } = this.state
    if (loading) {
      return (
        <LoadingContainer>
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </LoadingContainer>
      )
    }
    return (
      <div className="facebook-integration-container">
        <Helmet>
          <title>Facebook Integration</title>
        </Helmet>
        <BodyContainer>
          <ActionBar>
            <div style={{ display: "flex", alignItems: "center"}}>
              {savingError && (
                <ErrorMessage>{savingError}</ErrorMessage>
              )}
              <NewBoxButton
                text="Save"
                primary
                disabled={_.isEqual(originalInfo, info)}
                onClick={() => {
                  this.updateFacebookInfo()
                }}
              />
            </div>
            
          </ActionBar>
          <H3Title>Facebook Page Integration</H3Title>
          <div className="facebook-info-settings-container">
            <div style={{ padding: "8px 0 16px 0", display: "inline-block"}}>
              <RadioButton
                on={this.state.defaultApp}
                onClick={() => {
                  this.setState({
                    defaultApp: true
                  })
                }}
              >
                <div className="radio-button-border">
                  <div className="radio-button"></div>
                </div>
                <span>Default</span>
              </RadioButton>
              <RadioButton
                on={!this.state.defaultApp}
                onClick={() => {
                  const clone = _.cloneDeep(this.state.info)
                  clone.customApp = true
                  this.setState({
                    defaultApp: false,
                    info: clone
                  })
                }}
              >
                <div className="radio-button-border">
                  <div className="radio-button"></div>
                </div>
                <span>Use own Facebook App</span>
              </RadioButton>
            </div>
            {this.renderPageInfo()}
          </div>
          <div className="separator" />
          {ATValid && info?.pageId && info?.accessToken ?
              <>
                <H3Title>Persistent Menu Setting</H3Title>
                {this.pmSettings()}
              </>
            :
            null
          }
        </BodyContainer>
      </div>
    )
  }
}

export default Home
